import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import {DiscussionEmbed} from "disqus-react";
import { Link } from 'gatsby'
import LazyLoad from 'react-lazyload';
import CategoryPill from "../components/category.pill";
import Article from "../components/article";

class Post extends Component {
  render() {
    const { data } = this.props.data.allPrismicArticle.edges[0]

    //const prismicContent = data.prismic.allPages.edges[0]
    const prismicContent = this.props.data.allPrismicArticle.edges[0]
    const title = prismicContent.node.data.title.text
    const author = prismicContent.node.data.author.text
    const publish_date = prismicContent.node.data.publish_date
    const image = prismicContent.node.data.image.url
    const paragraph = prismicContent.node.data.paragraph.html
    const alt_text = prismicContent.node.data.image.alt

      const disqusConfig = {
          shortname: process.env.GATSBY_DISQUS_NAME,
          config: { identifier: this.props.uid },
        };


    if (!prismicContent) return null


    const relatedArticles = prismicContent.node.data.body.map((slice, index) => {
        if (slice.slice_type === 'related_content'){

            const articles = slice.items.map(articles => (
                <Article uid={articles.related_article.uid} data={articles.related_article.document.data} />
            ))

            return (
                <section  className="text-gray-300 body-font bg-auto pb-8">
                    <h1 className="text-4xl font-bold mb-2 text-pink-600 pb-4">Related Articles</h1>
                    {articles}
                </section>

            )


        }


    })

      console.log(relatedArticles)


    const blogSlices = prismicContent.node.data.body.map((slice, index) => {
      // Render the right markup for the given slice type

      // Table Slice
      if (slice.slice_type === 'table_2_col') {
        const rows = slice.items.map((row, rowIndex) => (
          <tr>
            <td className="border-solid border border-pink-600 py-1 px-2">{row.col_1.text}</td>
            <td className="border-solid border border-pink-600 py-1 px-2">{row.col_2.text}</td>
          </tr>
        ))

        return (
            <section  className="text-gray-300 body-font bg-auto pb-8">
                    <div className="mx-auto flex md:flex-row flex-col" >
                        <div className="flex flex-col w-full">
                            <h2 className="font-bold mb-4">{slice.primary.table_title.text}</h2>
                            <div className="overflow-x-auto">
                            <table className="table-auto w-full">
                              <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_1.text}</th>
                              <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_2.text}</th>
                              {rows}
                            </table>
                            </div>
                        </div>
                    </div>
            </section>
        )


      }

    if (slice.slice_type === 'table_3_col') {
        const rows = slice.items.map((row, rowIndex) => (
          <tr>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_1.text}</td>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_2.text}</td>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_3.text}</td>
          </tr>
        ))

        return (
            <section  className="text-gray-300 body-font bg-auto pb-8">
                    <div className="mx-auto flex  md:flex-row flex-col" >
                        <div className="flex flex-col w-full">
                            <h2 className="font-bold mb-4">{slice.primary.table_title.text}</h2>
                            <div className="overflow-x-auto">
                            <table className="table-auto w-full">
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_1.text}</th>
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_2.text}</th>
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_3.text}</th>

                              {rows}
                            </table>
                            </div>
                        </div>
                    </div>
            </section>
        )


      }

    if (slice.slice_type === 'table_4_col') {
        console.log("fliup")
        const rows = slice.items.map((row, rowIndex) => (
          <tr>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_1.text}</td>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_2.text}</td>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_3.text}</td>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_4.text}</td>
          </tr>
        ))

        return (
            <section  className="text-gray-300 body-font bg-auto pb-8">
                    <div className="mx-auto flex md:flex-row flex-col" >
                        <div className="flex flex-col w-full">
                            <h2 className="font-bold mb-4">{slice.primary.table_title.text}</h2>
                            <div className="overflow-x-auto">
                            <table className="table-auto w-full">
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_1.text}</th>
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_2.text}</th>
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_3.text}</th>
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_4.text}</th>

                              {rows}
                            </table>
                            </div>
                        </div>
                    </div>
            </section>
        )


      }
    if (slice.slice_type === 'table_5_col') {

        const rows = slice.items.map((row, rowIndex) => (
          <tr>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_1.text}</td>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_2.text}</td>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_3.text}</td>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_4.text}</td>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_5.text}</td>
          </tr>
        ))

        return (
            <section  className="text-gray-300 body-font bg-auto pb-8">
                    <div className="mx-auto flex md:flex-row flex-col" >
                        <div className="flex flex-col w-full">
                            <h2 className="font-bold mb-4">{slice.primary.table_title.text}</h2>
                            <div className="overflow-x-auto">
                            <table className="table-auto w-full">
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_1.text}</th>
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_2.text}</th>
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_3.text}</th>
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_4.text}</th>
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_5.text}</th>

                              {rows}
                            </table>
                                </div>
                        </div>
                    </div>
            </section>
        )


      }

    if (slice.slice_type === 'table_6_col') {

        const rows = slice.items.map((row, rowIndex) => (
          <tr>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_1.text}</td>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_2.text}</td>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_3.text}</td>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_4.text}</td>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_5.text}</td>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_6.text}</td>
          </tr>
        ))

        return (
            <section  className="text-gray-300 body-font bg-auto pb-8">
                    <div className="mx-auto flex md:flex-row flex-col" >
                        <div className="flex flex-col w-full">
                            <h2 className="font-bold mb-4">{slice.primary.table_title.text}</h2>
                            <div className="overflow-x-auto">
                            <table className="table-auto w-full">
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_1.text}</th>
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_2.text}</th>
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_3.text}</th>
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_4.text}</th>
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_5.text}</th>
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_6.text}</th>
                              {rows}
                            </table>
                                </div>
                        </div>
                    </div>
            </section>
        )


      }

    if (slice.slice_type === 'table_7_col') {

        const rows = slice.items.map((row, rowIndex) => (
          <tr>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_1.text}</td>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_2.text}</td>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_3.text}</td>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_4.text}</td>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_5.text}</td>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_6.text}</td>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_7.text}</td>
          </tr>
        ))

        return (
            <section  className="text-gray-300 body-font bg-auto pb-8">
                    <div className="mx-auto flex md:flex-row flex-col" >
                        <div className="flex flex-col w-full">
                            <h2 className="font-bold mb-4">{slice.primary.table_title.text}</h2>
                            <div className="overflow-x-auto">
                            <table className="table-auto w-full">
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_1.text}</th>
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_2.text}</th>
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_3.text}</th>
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_4.text}</th>
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_5.text}</th>
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_6.text}</th>
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_7.text}</th>
                              {rows}
                            </table>
                                </div>
                        </div>
                    </div>
            </section>
        )


      }

    if (slice.slice_type === 'table_8_col') {

        const rows = slice.items.map((row, rowIndex) => (
          <tr>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_1.text}</td>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_2.text}</td>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_3.text}</td>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_4.text}</td>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_5.text}</td>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_6.text}</td>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_7.text}</td>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_8.text}</td>
          </tr>
        ))

        return (
            <section  className="text-gray-300 body-font bg-auto pb-8">
                    <div className="mx-auto flex md:flex-row flex-col" >
                        <div className="flex flex-col w-full">
                            <h2 className="font-bold mb-4">{slice.primary.table_title.text}</h2>
                            <div className="overflow-x-auto">
                            <table className="table-auto w-full">
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_1.text}</th>
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_2.text}</th>
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_3.text}</th>
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_4.text}</th>
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_5.text}</th>
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_6.text}</th>
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_7.text}</th>
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_8.text}</th>
                              {rows}
                            </table>
                                </div>
                        </div>
                    </div>
            </section>
        )


      }

    if (slice.slice_type === 'table_9_col') {

        const rows = slice.items.map((row, rowIndex) => (
          <tr>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_1.text}</td>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_2.text}</td>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_3.text}</td>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_4.text}</td>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_5.text}</td>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_6.text}</td>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_7.text}</td>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_8.text}</td>
                <td className="border-solid border border-pink-600 py-1 px-2">{row.col_9.text}</td>
          </tr>
        ))

        return (
            <section  className="text-gray-300 body-font bg-auto pb-8">
                    <div className="mx-auto flex md:flex-row flex-col" >
                        <div className="flex flex-col w-full">
                            <h2 className="font-bold mb-4">{slice.primary.table_title.text}</h2>
                            <div className="overflow-x-auto">
                            <table className="table-auto w-full">
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_1.text}</th>
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_2.text}</th>
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_3.text}</th>
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_4.text}</th>
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_5.text}</th>
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_6.text}</th>
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_7.text}</th>
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_8.text}</th>
                                <th className="border-solid border border-pink-600 bg-pink-600 font-bold text-left py-1 px-2">{slice.primary.col_header_9.text}</th>
                              {rows}
                            </table>
                                </div>
                        </div>
                    </div>
            </section>
        )


      }

    // Text paragraph Slice
    if (slice.slice_type === 'text') {

        return (

            <section className="text-gray-200 body-font bg-auto pb-8">
                    <div className="mx-auto flex  md:flex-row flex-col  " >
            <div
                className="c-article-content leading-7 article-paragraph"
                dangerouslySetInnerHTML={{ __html: slice.primary.text.html }}
              />
                    </div>
            </section>
        )

      // Return null by default
      }
      return null
    })



    const meta_title = prismicContent.node.data.meta_title.text || title;
    const og_image = prismicContent.node.data.og_image.url;
    const keywords = prismicContent.node.data.keywords.text;
    const meta_description = prismicContent.node.data.meta_description.text;

    return (

      <Layout>
        <SEO
          title={`${meta_title}`}
          keywords={[keywords]}
          description={meta_description}
          og_image={og_image}
        />

        <section className="header px-6 pt-20 lg:pt-32 text-sm">
                <div className="flex flex-wrap mx-auto items-center mb-8">
                            <h2 className="text-gray-300 mr-4 font-semibold">
                                <Link to="/">
                                Homepage</Link>
                            </h2>

                            <h2 className="text-gray-300 mr-4 font-semibold">
                                <Link to="/articles/">
                                Articles</Link>
                            </h2>

                            <h2 className="text-gray-400 font-normal">
                                {title}
                        </h2></div>
            </section>

        <div className="content-header-container mx-auto pt-8 lg:px-0 px-4">
               <section  className="text-gray-300 body-font bg-auto pb-2">
                    <div className="mx-auto flex  md:flex-row flex-col " >
                    <div className="">

                        <h1 className="text-4xl font-bold mb-2 text-pink-600">
                            {title}
                        </h1>
                    </div>



                    </div>
                </section>

                <section  className="text-gray-200 body-font bg-auto pb-2">
                    <div className="mx-auto flex md:flex-row flex-col " >
                    <div className="">

                                <div
                        className="c-article-content leading-7 article-paragraph"
                        dangerouslySetInnerHTML={{ __html: paragraph }}
                      /></div>

                    </div>
                    <div className="mx-auto flex flex-col md:flex-row mb-8">
                            <span className="mr-2"><CategoryPill category={prismicContent.node.data.category}/></span>
                            <span className="text-indigo-400 mt-2 md:mt-0 md:ml-2 md:mr-2">{publish_date}</span>
                            <span className="text-gray-400 mt-2 md:mt-0 md:ml-2">by {author}</span>
                        </div>


                </section>

                <section className="mx-auto mb-8">
                    <img className="inline-block h-auto w-full rounded-lg" src={image} alt={alt_text}/>
                </section>

                {blogSlices}



                {relatedArticles}


                <section className="mx-auto mt-8 mb-16">
                    <div className="rounded-lg text-gray-300 shadow-lg bg-gray-800 h-44 flex justify-center items-center flex-col">
                        <span className="lg:mb-4 text-lg lg:text-xl lg:p-0 p-4 flex text-center">Get an overview of the Baltic tech scene straight to your inbox</span>
                        <Link to="/subscription/">
                                <button className="bg-pink-600 rounded py-1 px-2">
                                    Subscribe
                                </button>
                            </Link>
                    </div>
                </section>






                <LazyLoad height={200}>
                    <DiscussionEmbed {...disqusConfig} />
                </LazyLoad>

          </div>


      </Layout>
    )
  }
}

export default Post

export const postQuery = graphql`
query Post($uid: String) {
  allPrismicArticle(filter: {uid: {eq: $uid}}) {
    edges {
      node {
        data {
          body {
            ... on PrismicArticleBodyTable2Col {
              id
              slice_type
              items {
                col_1 {
                  text
                }
                col_2 {
                  text
                }
              }
              primary {
                table_title {
                  text
                }
                col_header_1 {
                  text
                }
                col_header_2 {
                  text
                }
              }
            }
            ... on PrismicArticleBodyText {
              id
              slice_type
              primary {
                text {
                  html
                }
              }
            }
            ... on PrismicArticleBodyTable3Col {
              id
              slice_type
              items {
                col_1 {
                  text
                }
                col_2 {
                  text
                }
                col_3 {
                  text
                }
              }
              primary {
                col_header_1 {
                  text
                }
                col_header_2 {
                  text
                }
                col_header_3 {
                  text
                }
                table_title {
                  text
                }
              }
            }
            ... on PrismicArticleBodyTable4Col {
              id
              items {
                col_1 {
                  text
                }
                col_2 {
                  text
                }
                col_3 {
                  text
                }
                col_4 {
                  text
                }
              }
              primary {
                col_header_1 {
                  text
                }
                col_header_2 {
                  text
                }
                col_header_3 {
                  text
                }
                col_header_4 {
                  text
                }
                table_title {
                  text
                }
              }
              slice_type
            }
            ... on PrismicArticleBodyTable5Col {
              id
              primary {
                col_header_1 {
                  text
                }
                col_header_2 {
                  text
                }
                col_header_3 {
                  text
                }
                col_header_4 {
                  text
                }
                col_header_5 {
                  text
                }
                table_title {
                  text
                }
              }
              slice_type
              items {
                col_1 {
                  text
                }
                col_2 {
                  text
                }
                col_3 {
                  text
                }
                col_4 {
                  text
                }
                col_5 {
                  text
                }
              }
            }
            ... on PrismicArticleBodyTable6Col {
              id
              slice_type
              items {
                col_1 {
                  text
                }
                col_2 {
                  text
                }
                col_3 {
                  text
                }
                col_4 {
                  text
                }
                col_5 {
                  text
                }
                col_6 {
                  text
                }
              }
              primary {
                col_header_1 {
                  text
                }
                col_header_2 {
                  text
                }
                col_header_3 {
                  text
                }
                col_header_4 {
                  text
                }
                col_header_5 {
                  text
                }
                col_header_6 {
                  text
                }
                table_title {
                  text
                }
              }
            }
            ... on PrismicArticleBodyTable7Col {
              id
              slice_type
              items {
                col_1 {
                  text
                }
                col_3 {
                  text
                }
                col_2 {
                  text
                }
                col_4 {
                  text
                }
                col_5 {
                  text
                }
                col_6 {
                  text
                }
                col_7 {
                  text
                }
              }
              primary {
                col_header_2 {
                  text
                }
                col_header_1 {
                  text
                }
                col_header_3 {
                  text
                }
                col_header_4 {
                  text
                }
                col_header_5 {
                  text
                }
                col_header_6 {
                  text
                }
                col_header_7 {
                  text
                }
                table_title {
                  text
                }
              }
            }
            ... on PrismicArticleBodyTable8Col {
              id
              slice_type
              items {
                col_1 {
                  text
                }
                col_2 {
                  text
                }
                col_3 {
                  text
                }
                col_4 {
                  text
                }
                col_5 {
                  text
                }
                col_6 {
                  text
                }
                col_7 {
                  text
                }
                col_8 {
                  text
                }
              }
              primary {
                col_header_1 {
                  text
                }
                col_header_2 {
                  text
                }
                col_header_3 {
                  text
                }
                col_header_4 {
                  text
                }
                col_header_5 {
                  text
                }
                col_header_6 {
                  text
                }
                col_header_7 {
                  text
                }
                col_header_8 {
                  text
                }
                table_title {
                  text
                }
              }
            }
            ... on PrismicArticleBodyTable9Col {
              id
              slice_type
              items {
                col_1 {
                  text
                }
                col_2 {
                  text
                }
                col_3 {
                  text
                }
                col_5 {
                  text
                }
                col_4 {
                  text
                }
                col_6 {
                  text
                }
                col_7 {
                  text
                }
                col_8 {
                  text
                }
                col_9 {
                  text
                }
              }
              primary {
                table_title {
                  text
                }
                col_header_1 {
                  text
                }
                col_header_2 {
                  text
                }
                col_header_3 {
                  text
                }
                col_header_4 {
                  text
                }
                col_header_5 {
                  text
                }
                col_header_6 {
                  text
                }
                col_header_7 {
                  text
                }
                col_header_8 {
                  text
                }
                col_header_9 {
                  text
                }
              }
            }
            ... on PrismicArticleBodyRelatedContent {
              id
              slice_type
              slice_label
              items {
                related_article {
                  id
                  uid
                  document {
                    ... on PrismicArticle {
                      id
                      uid
                      data {
                        image {
                          alt
                          url
                        }
                        featured_paragraph {
                          html
                        }
                        category
                        publish_date
                        title {
                          text
                        }
                        author {
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          category
          featured
          featured_paragraph {
            html
          }
          image {
            url
            alt
          }
          paragraph {
            html
          }
          title {
            text
          }
          publish_date(formatString: "MMM Do YYYY")
          author {
            text
          }
          og_image {
            url
          }
          meta_title {
            text
          }
          meta_description {
            text
          }
          keywords {
            text
          }
        }
      }
    }
  }
}



`
  // query Post($uid: String) {
  //   post: prismicArticle(id: { eq: $uid }) {